.opinionBox{
    padding-left: 2%;
    padding-right: 2%;
    /* border: 1px solid #fff; */
    margin-bottom: 10%;
    border-radius: 12px;
    box-shadow: 2px 2px 20px #fff;
}

.opinonText{
    padding: 12%;
    color: #fff;
    text-shadow: 1px 1px 2px #00ffff;
}