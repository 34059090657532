#parent {
  color: #fff;
  padding: 0;
  width: 100%;
  text-align: center;
}
.fab {
    padding: 20px;
    font-size: 30px;
    color: #fff;
    width: 50px;
    text-align: center;
    text-decoration: none;
}
.margin_auto{
  margin: 0 auto;
}
.social-icons{
  list-style: none;
  color: #fff;
  font-size: 1.75em;
}
.social-icons:hover i.fa-brands{
  background-color: #fff;
  color: #000;
  transition: all .3s ease-in-out;
  border-radius: 12%;
}
footer{
  background-color: rgba(50, 50, 50,.9);
}
