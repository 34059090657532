@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap');
html{
    padding: 0;
    margin: 0;

}
@media screen and (max-width: 575px) {
    html{
        text-align: center;
    }
}
body{
    font-family: 'Roboto Condensed', sans-serif !important;
    /* font-size: 1.5em !important; */
    margin: 0;
}

