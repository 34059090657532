h4.role {
    font-size: 1.2rem !important;
    font-weight: 500 !important;
    line-height: 1.2;
    color: #2c126a;
    font-style: normal ;
  }
.margin__top{
  margin-top: 6rem;
}

  @media screen and (max-width: 997px) {
    .margin__top{
      margin-top: .5rem;
    }
   
  }