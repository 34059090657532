.faculty__containar {
  max-width: 100vw;
  margin-top: 6rem;
  font-family: "Montserrat", sans-serif;
}
.section-title {
  width: 100%;
  text-align: center;
  padding: 45px 0 30px;
}
.outer {
  padding: 3% 4%;
  border-radius: 6rem;
  margin-top: 4%;
  margin-bottom: 5%;
  border: 1px solid #00ffff;
}
.outer:hover {
  box-shadow: 0px 0px 20px #00ffff;
  transition: all 0.5s ease-in-out;
}
.outer .members__box{
  color: #000;
  box-shadow: 0 0 10px #00ffff;
  transition: all 1s;
}

.outer .members__box:hover {
  box-shadow: 0 0 50px rgb(15, 151, 255);
}

.team-5 {
  margin-bottom: 30px;
}
.members__box {
  cursor: grab;
  border-radius: 3rem;
}
.team-5 .team-img {
  position: relative;
  font-size: 0;
  text-align: center;
}
.team-5 .team-img img {
  width: 175px;
  height: 200px;
  object-fit: cover;
  border-radius: 100%;
  border: 20px solid #f0f8ff;
}
.team-5 .team-content {
  padding: 80px 20px 20px;
  margin-top: -80px;
  text-align: center;
  background: #f0f8ff;
  border-radius: 10px;
}
.team-5 .team-content h2 {
  font-family: "Montserrat", sans-serif;
  letter-spacing: 2px;
  font-size: 18px !important;
  font-weight: 500 !important;
}

h6 {
  font-size: 12.5px !important;
}
.team-5 .team-content h4 {
  font-style: italic;
  letter-spacing: 1px;
  margin-bottom: 0;
}
@media screen and (max-width: 997px) {
  .faculty__containar {
    max-width: 100vw;
    margin-top: 0;
    font-family: "Montserrat", sans-serif;
  }
}
@media screen and (max-width: 530px) {
  .section-title {
    padding: 50px 0 30px;
  }
}