.rcr{
  color: antiquewhite;
  justify-content: center;
  font-size: 45px;
  margin-top: 120px;
  font-family: "Montserrat", sans-serif;
}
.gallery {
  --g: 3px; /* the gap */
  
  display: grid;
  width: 1220px; /* the size */
  aspect-ratio: 1.0;
  grid: auto-flow 90fr/repeat(5,10fr);
  gap: var(--g);
  place-content: center;
  margin-top: 16%;
  margin-left: 18%;
}
.gallery img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: grayscale(100);
  cursor: pointer;
  transition: .3s
}
.gallery img:hover {
  filter: grayscale(0);
}
.gallery img:nth-child(2) {
  grid-area: 1/2/span 2/span 2;
  clip-path: polygon(0 0,100% 0,100% 100%,calc(50% + var(--g)/4) 100%,0 calc(50% - var(--g)/4))
}
.gallery img:nth-child(3) {
  grid-area: 2/1/span 2/span 2;
  clip-path: polygon(0 0,calc(50% - var(--g)/4) 0,100% calc(50% + var(--g)/4),100% 100%,0 100%);
}
.gallery img:nth-child(7) {
  grid-area: 1/2/span 2/span 2;
  clip-path: polygon(0 0,100% 0,100% 100%,calc(50% + var(--g)/4) 100%,0 calc(50% - var(--g)/4))
}
.gallery img:nth-child(8) {
  grid-area: 2/1/span 2/span 2;
  clip-path: polygon(0 0,calc(50% - var(--g)/4) 0,100% calc(50% + var(--g)/4),100% 100%,0 100%);
}

@media screen and (max-width:990px) {
 
  .gallery {
    --g: 3px; /* the gap */    
    width: auto;
    display: grid;
    aspect-ratio: 1.0;
    grid: auto-flow 90fr/repeat(5,10fr);
    gap: var(--g);
    place-content: center;
    margin-top: 12%;
    margin-left: 2%;
    margin-right: 2%;
  }
  .rcr{
    font-size: 30px;
    font-weight: 500;
  }
}