h2.membersName {
    font-family: 'Montserrat', sans-serif;
    font-size: 15px !important;
    letter-spacing: 2px;
    margin-top: 1em;
  }
.team__member{
    margin-top: 6rem;
}  
.section-title h1,.section-title h3{
    color: #fff;
}
.section-title h3{
    width: 35%;
    margin: .5rem auto;
    border-bottom: 2px solid #00f;
}
.team-5 .team-img img {
    width: 175px;
    height: 200px;
    object-fit: cover;
    border-radius: 100%;
    border: 12px solid #cbe7ff;
}
.team-5 .team-content {
    padding: 80px 20px 20px;
    margin-top: -80px;
    text-align: center;
    background: #f0f8ff;
    border-radius: 10px;
}
h4.role {
    font-size: 1.51rem!important;
    font-weight: 600!important;
    line-height: 1.4;
    color: #2c126a;
    font-style: italic!important;
}
@media screen and (max-width: 530px) {
    .section-title h3{
        width: 60%;
        margin: .5rem auto;
        border-bottom: 2px solid #00f;
    }
}